<template>
  <div>
    <v-system-bar
      v-if="visible"
      id="home-cookie-bar"
      app
      color="primary"
      elevation="1"
      height="60"
    >
      <v-spacer />
      <p class="white--text mt-4">
        <span v-show="$vuetify.breakpoint.mdAndUp">
          This website collects statistics about visitors. We use them to improve the website.
        </span>
        <span v-show="!$vuetify.breakpoint.mdAndUp">
          We use cookies.
        </span>
        You can
        <a
          class="accent--text text-decoration-underline"
          @click="optout"
          v-text="'opt out of all tracking'"
        />
        or learn more in the
        <router-link
          :to="{ name: 'Privacy Policy' }"
          class="accent--text"
        >
          privacy policy.
        </router-link>
      </p>

      <v-spacer />
      <v-btn
        outlined
        tile
        color="white"
        @click="dismiss"
      >
        OK
      </v-btn>
      <v-spacer />
    </v-system-bar>
  </div>
</template>

<script>
  export default {
    name: 'HomeCookieBar',

    data: () => ({
      visible: true,
    }),

    methods: {
      dismiss () {
        this.$set(this, 'visible', false)
      },
      optout () {
        this.$ga.disable()
        console.log('Tracking disabled')
        this.dismiss()
      },
    },
  }
</script>

<style>

</style>
